<template>
    <finanzausgleich-comp></finanzausgleich-comp>
</template>

<script>
    import FinanzausgleichComp from "../components/Dokumente/Finanzausgleich";
    export default {
        name: "Finanzausgleich",
        components: {
            FinanzausgleichComp
        }
    }
</script>

<style scoped>

</style>